import { cn } from '@/Lib'
import { ComponentPropsWithoutRef } from 'react'

function DescriptionList({
  className,
  ...props
}: ComponentPropsWithoutRef<'dl'>) {
  return (
    <dl
      className={cn('grid grid-cols-[auto,1fr] gap-2', className)}
      {...props}
    />
  )
}

function DescriptionTerm({
  className,
  ...props
}: ComponentPropsWithoutRef<'dt'>) {
  return <dt className={cn('text-medium', className)} {...props} />
}

function DescriptionDetails({
  className,
  ...props
}: ComponentPropsWithoutRef<'dd'>) {
  return (
    <dd
      className={cn('justify-self-end font-medium text-medium', className)}
      {...props}
    />
  )
}

export { DescriptionDetails, DescriptionList, DescriptionTerm }
